@import url('https://fonts.googleapis.com/css2?family=Cormorant+Unicase:wght@600&family=Noto+Sans+TC&family=Noto+Serif+TC:wght@600&display=swap&family=Bebas+Neue&display=swap')
@import "reset"
@import "helpers/variables"
@import "helpers/extend"
@import "helpers/mixin"
@import "node_modules/bootstrap/scss/bootstrap"

.ratio-hero-video
  @extend %img-ratio
  padding-bottom: 42%
.object-fill-cover
  object-fit: cover
.object-fill-contain
  object-fit: contain
.hidden-text 
  visibility: hidden
  width: 1px
  height: 1px
  position: absolute
  left: -9999px
[v-cloak]
  display: none
.heroVideo
  position: relative
  .video-wrapper
    line-height: 0
    +sm-down
      height: 80vh
      min-height: 460px
      //   background: #000 url('@images/kv.jpg') no-repeat center center
      //   background-size: cover
    video
      width: 100%
      height: 100%
      +sm-down
        height: 80vh
        min-height: 460px
        object-fit: cover

  .intro-wrapper
    position: absolute
    top: 0%
    left: 0%
    width: 100%
    height: 100%
    color: #FFF
    display: flex
    align-items: center
    justify-content: center
    background: rgba(0,0,0,.6)
    padding-top: 4rem
    +sm-down
      padding: 30px 15px 0 15px
      text-align: center
    h2
      font-size: 1.3rem
      font-weight: 600
      text-transform: uppercase
      text-shadow: 0 2px 10px rgba(0,0,0,.8)
      +md-up
        font-size: 1.6rem
    h3
      font-size: 2.3rem
      font-weight: 600
      text-transform: uppercase
      text-shadow: 0 2px 10px rgba(0,0,0,.8)
      +md-up
        font-size: 3.6rem
      +lg-up
        font-size: 4.8rem
      strong
        color: $primary
        display: block
    p
      font-size: 1.1em
      max-width: 680px
      color: rgba(#FFF, 0.8)
      text-shadow: 0 2px 10px rgba(0,0,0,.8)
      +md-up
        font-size: 1.5em
      +lg-up
        font-size: 1.85em
    .btn
      padding: 0.5rem 2.5rem
      margin-top: 2rem
      +md-up
        padding: 0.75rem 2.5rem


.fix-transform-bug
  -webkit-transform-style: preserve-3d

body
  position: relative
  background-color: white
  color: $font
  font-size: 14px
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: Arial, 'Noto Sans TC', 'Raleway', "Oswald", Roboto, 'Microsoft JhengHei', "Microsoft YaHei", "Apple Color Emoji", Helvetica, Arial, sans-serif
  &.modal-open
    padding-right: 0px !important
    .mm-slideout
      z-index: inherit !important

.bodoni
  font-family: 'Didot', 'Cormorant Unicase', 'Noto Serif TC', Helvetica, Arial, serif
  font-weight: bold
.neue
  font-family: "Bebas Neue", sans-serif
  font-weight: bold
  text-transform: uppercase
  letter-spacing: 2px

a
  color: $font
  text-decoration: none
  &:hover,&:focus,&:active,&.active
    color: $primary
    text-decoration: none
    outline: 0
  &.primary-link
    color: $primary
    text-decoration: underline
    &:hover,&:focus,&:active,&.active
      color: $primary

.opacity-50
  opacity: 0.5
.opacity-80
  opacity: 0.8

.btn
  display: inline-flex
  align-items: center
  justify-content: center
  transition: .2s all ease-out
  border-radius: 0
  &:focus
    outline: 0
    box-shadow: none
  &.more-y
    padding-left: 1.2rem
    padding-right: 1.2rem
  &.btn-outline-white
    border-color: #FFFFFF
    color: $primary
  .arr-right
    display: inline-block
    width: 8px
    margin-left: .5rem
    svg
      width: 100%
      float: left
  &.max-width-210
    max-width: 210px
    width: 100%
    &:hover
      letter-spacing: 2px
  &.w-100
    &:hover
      letter-spacing: 2px
  &.btn-primary
    color: #fff
  &.btn-danger
    color: #fff
    &:hover,&:focus
      color: #fff
  &.btn-line
    color: #fff
    background-color: #01C101
    &:hover,&:focus
      color: #fff
      background-color: darken(#01C101, 10%)
  &.btn-download
    border-radius: 8px
    &:hover,&:focus
      background-color: #FFFFFF

@keyframes animatedBackground
  0%
    background-position: 0 0
  100%
    background-position: 300% 0

.page-header
  top: 0
  left: 0
  width: 100%
  z-index: 999
  position: fixed
  top: 0
  left: 0
  width: 100%
  transition: 0.2s transform cubic-bezier(0.60, 0, 0.075, 1)
  &.isHide
    transform: translateY(-100%)
  &.isSticky
    z-index: 1203 !important
    backdrop-filter: blur(5px)
    background: rgba(255,255,255,0.5)
    @extend %box-shadow
    .navbar-nav
      .nav-link
        color: #000
        &:hover, &:focus, &:active, &.active
          color: #000
          font-weight: blod

  .navbar
    justify-content: initial
    +sm-down
      justify-content: center
    .isSticky &
      padding: .25rem 0
    .navbar-collapse
      justify-content: flex-end

    .menutoggler
      border: 0
      width: 50px
      height: 50px
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      top: 50%
      left: 0
      transform: translateY(-50%)
      &:hover, &:focus
        outline: 0
        .menu-icon
          &_bar
            background-color: $primary
    .navbar-brand
      font-size: 2.2rem
      +lg-down
        margin: .25rem auto
        font-size: 1.62rem
  .navbar-nav
    font-weight: 200
    font-size: 1rem
    flex-direction: row
    .nav-item
      position: relative
      font-weight: 400
      padding: 0 .5rem
      display: flex
      align-items: center
      .btn
        padding: .5rem 2rem
        font-size: 1rem
        +sm-down
          padding: .5rem 1.5rem
          font-size: 0.875rem
      svg
        width: 24px
        height: 24px
        stroke-width: 1px
        path
          stroke-width: 1px
    .nav-link
      color: #FFFFFF
      position: relative
      z-index: 10
      padding: 1rem
      display: flex
      align-items: center
      font-size: 1rem
      +md-down
        padding: .75rem 1rem
      &:hover, &:focus, &:active, &.active
        color: #FFFFFF
        font-weight: blod
        &::before
          width: 80%
      .isSticky &
        font-size: 1rem
      .text
        z-index: 30
        position: relative
      .icon
        width: 17px
        display: inline-block
        margin-right: .3rem
        path
          fill: #ffffff
      &::before
        @extend %menu-animation
        animation-delay: .2s
        content: ''
        display: block
        position: absolute
        height: 2px
        width: 0%
        left: 50%
        bottom: 0
        transform: translateX(-50%)
        max-width: auto
        background-color: $warning
        z-index: 0
  .navbar-brand
    padding: 0
    color: #FFF
    .header-logo
      width: 220px
      transition: .3s width ease-out
      line-height: 0
      +md-down
        width: 148px
      svg
        width: 100%
    // .header-logo-icon
    //   display: block
    //   width: 46px
    //   height: 46px
    //   +sm-up
    //     display: none
    //   svg
    //     width: 100%
    //     height: 100%
    .branding-name
      +font-size(24px)
      color: $gray-900
      font-weight: 500
      margin-bottom: 0

.mm-menu
  // background: $gray-700
  &.mm-theme-dark
    background: $gray-700
    .mm-listview
      .nav-item
        &.active,&:hover,&:focus
          color: $primary
          background: darken($gray-700, 5%)
          .nav-link, .mm-next
            color: $primary
        .nav-link, .mm-next
          color: $white
      a
        color: $white
        &.active,&:hover,&:focus
          color: $primary
.slick-slider
  .slick-track
    transform: none
  .slick-list
    transform: none

.slick-slide
  img
    width: 100%
.slick-arrow
  position: absolute
  top: 50%
  text-indent: 999px
  overflow: hidden
  width: 46px
  height: 46px
  margin: 0
  border: 0
  background: #fff
  z-index: 561
  border-radius: 50%
  &::after
    content: ''
    display: block
    border-left: 2px solid $gray-900
    border-bottom: 2px solid $gray-900
    transform: rotate(45deg)
    width: 14px
    height: 14px
    position: absolute
    top: 16px
    left: 18px
  &:hover
    outline: 0
    background: $primary
    &::after
      border-color: #FFF
  &:focus
    outline: 0
  &.slick-disabled
    opacity: .3
    cursor: not-allowed
    background: #FFF
    &:after
      border-color: $gray-900
  &.slick-prev
    left: 10px
  &.slick-next
    right: 10px
    &::after
      right: 18px
      left: auto
      transform: rotate(225deg)
.slick-dots
  margin: 0
  padding: 0
  text-align: center
  position: absolute
  bottom: 0px
  left: 0
  width: 100%
  > li
    display: inline-block
    line-height: 0
    &.slick-active
      > button
        background: $gray-900
        border-color: $gray-900
    > button
      width: 10px
      height: 10px
      background: transparent
      border: 1px solid $gray-500
      border-radius: 50%
      padding: 0
      &:hover, &:focus
        margin: 0 3px
      margin: 0 3px
      text-indent: -9999px
      overflow: hidden
      img
        transition: width .2s cubic-bezier(0, 0, 0.44, 1.18)
      &:hover,&:focus,&:active,&.active
        outline: 0 !important

//default state
$mu-icon-size:  24px
.menu-icon
  // height: $mu-icon-size
  width: 24px
  // position: absolute
  z-index: 2
  cursor: pointer
  &_bar
    height: 2px
    width: 24px
    display: block
    background-color: #FFFFFF
    margin-bottom: 6px
    transition: transform .2s ease, background-color .5s ease
    .isSticky &
      background-color: #000000
  &_bar-first
    // width: $mu-icon-size / 2
  &_bar-last
    margin-bottom: 0
    // width: $mu-icon-size / 2
    float: right


.mm-opened
  .mm-page
    overflow: hidden
  // #LaunchPage
  //   position: relative
  .menu-icon
    &_bar
      transform: translateX(0px) rotate(-45deg)
    &_bar-first
      transform: translate(1px, 3px) rotate(45deg)
      width: calc($mu-icon-size / 2)
    &_bar-last
      transform: translate(-1px, -3px) rotate(45deg)
      width: calc($mu-icon-size / 2)
      float: right

section
  &.border-top
    border-color: rgba($gray-200, 0.2) !important
  &.spacing-y
    padding-top: 80px
    padding-bottom: 80px
    +md-down
      padding-top: 50px
      padding-bottom: 50px
  &.home-upcoming
    overflow: hidden
  &.section-padding
    padding: 5rem 0
    +md-down
      padding: 3rem 0
  hgroup
    margin-bottom: 3rem
    +md-down
      margin-bottom: 1.5rem
    h2
      font-weight: 600
      color: $dark
      +font-size(38px)
      margin-bottom: .75rem
      +md-up
        +font-size(42px)
        margin-bottom: 1.5rem
    h3
      font-weight: normal
      color: $gray-500
      +font-size(16px)
      +md-up
        +font-size(20px)

  .section-container
    padding-left: 20px
    padding-right: 20px
    +md-up
      padding-left: 80px
      padding-right: 80px
    +lg-up
      padding-left: 100px
      padding-right: 100px

.hero-banner
  position: relative
  .slide-item
    position: relative
    .more
      position: absolute
      bottom: 0
      right: 73.45%
      +sm-down
        display: none
      .btn
        padding: 1.5rem 2.8rem
        +lg-down
          padding: 1.2rem 2rem
    .item-img
      +md-down
        position: relative
        padding-bottom: 110%
      img
        +md-down
          position: absolute
          width: 100%
          height: 100%
          top: 0
          left: 0
          object-fit: cover

.page-footer
  .footer-below
    background-color: #151515
    font-size: 0.875rem
    a
      color: #FFF
  .copyright
    padding: 1rem 0
    color: rgba(255,255,255,.5)
    text-align: center
    a
      display: inline-block
      padding: 2px 10px
  .footer-above
    padding: 3rem 0
    +md-down
      padding: 2rem 0
    +sm-down
      padding: 2.8rem 0
    .above-content
      display: flex
      flex-wrap: wrap
      position: relative
      +sm-down
        align-items: center
  .footer-navi
    +font-size(14px)
    font-weight: 400
    padding: 0
    > li
      &:not(:last-child)
        +sm-down
          margin-bottom: 1.25rem
    h4
      font-weight: 500
      +font-size(16px)
      margin-bottom: 1rem
      +md-down
        margin-bottom: 0.25rem
        font-weight: 600
    ul
      margin: 0
      padding: 0
      li
        padding: .25rem 0
    a
      text-decoration: underline
      &:hover,&:focus,&:active,&.active
        color: $primary
    b
      font-weight: 500
  .brand-area
    margin-bottom: .5rem
    +md-down
      margin-bottom: 1rem
    .brand-img
      max-width: 96px
      +sm-down
        max-width: 68px
    h3
      +font-size(28px)
      font-weight: 500
      margin-bottom: 0
  .company-desc
    padding-right: 5rem
    line-height: 1.3
    +lg-down
      padding-right: 3rem
    +md-down
      flex: 0 0 100%
      width: 100%
      margin-bottom: 1.5rem
      padding-right: 0
    p
      +font-size(14px)
      margin-bottom: 10px
    .artist
      +font-size(13px)
      font-weight: 300
      margin-bottom: 0
  .company-info
    +font-size(14px)
    line-height: 1.3
    ul
      padding-left: 0
      margin-bottom: 0
      li
        &:not(:last-child)
          margin-bottom: 6px
    +md-down
      flex: 0 0 100%
      width: 100%


.pagination
  display: flex
  align-items: center
  justify-content: center
  margin-top: 2rem
  margin-bottom: 2rem
  .page-item
    &:hover, &:focus, &:active, &.active
      .page-link
        background-color: $primary
        color: $white
        border-color: $primary
    &.disabled
      .page-link
        background-color: rgba($gray-200,0.2)
        color: $gray-500
        border-color: $gray-200
        opacity: .5
    .page-link
      border-radius: 50%
      width: 36px
      height: 36px
      margin: 0 5px
      display: flex
      align-items: center
      justify-content: center
      padding: 0
      text-align: center
      background-color: transparent
      color: $font
      +font-size(16px)
      background: #000
      border: 1px solid $gray-500
      +sm-down
        +font-size(14px)
      &.previous, &.next
        +md-up
          width: auto
          padding: 0 12px
          border-radius: 50px
      &.previous
        +md-up
          padding-right: 20px
      &.next
        +md-up
          padding-left: 20px
      svg
        width: 20px

.static-content
  color: $font
  +font-size(15px)
  line-height: 1.85
  p, li
    margin-bottom: 0.5rem
  ul
    padding-left: 0
    &.first
      > li
        margin-bottom: 25px
    &.style-disc
      > li
        list-style: disc
  li
    list-style-position: inside
    > ul, ol
      padding-left: 20px
    > ol
      list-style: cjk-ideographic
  img
    max-width: 100%
  h1,h2,h3,h4,h5,h6
    font-weight: 600
    color: $font
    margin-bottom: 15px
  h1
    +font-size(32px)
  h2
    +font-size(24px)
  h3
    +font-size(20px)
  h4
    +font-size(18px)
  h5
    +font-size(16px)
  h6
    +font-size(14px)

.galleryModal
  .gallery-warpper
    figure
      figcaption
        font-size: 1.25rem
        color: $font-dark
        margin-bottom: .85rem

// 首頁-最新消息
.home-news
  position: relative
  overflow: hidden
.card
  border: 0
  border-radius: 8px
.card-board
  border-radius: 8px
  position: relative
  overflow: hidden
  &:hover, &:focus
    .img
      &:after
        background: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0))
      .card-img
        transform: scale(1.06)
    .card-body
      &:after
        width: 100%
  .card-over-link
    @extend %card-over-link
  .img
    @extend %img-scale
    &:after
      content: ''
      display: block
      width: 100%
      height: 40%
      position: absolute
      bottom: 0
      left: 0
      transition: .4s transform cubic-bezier(0.60, 0, 0.075, 1)
      transform: translateY(0%)
      background: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0))
      +md-down
        background: linear-gradient(0deg, rgba(0,0,0,.5), rgba(0,0,0,0))
    img
      object-fit: cover
  .card-body
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    padding: 1rem 1rem 1.2rem 1rem
    transition: .4s transform cubic-bezier(0.60, 0, 0.075, 1)
    +sm-down
      padding: .8rem .8rem 1rem .8rem
    &:after
      +md-up
        content: ''
        display: block
        width: 0
        height: 4px
        position: absolute
        bottom: 0
        left: 0
        background-color: $primary
        transition: .4s width .2s cubic-bezier(0.60, 0, 0.075, 1)
    a
      color: #fff
    .date
      font-size: .8rem
      color: $success
      margin-bottom: .1rem
    .card-title
      color: #fff
      font-size: 1.1rem
      text-shadow: 0 1px 2px rgba(0,0,0,.8)
      margin-bottom: 0
      +sm-down
        font-size: 1rem
        display: block
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
      +sm-up
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        white-space: normal
      &.font-md-small
        +md-up
          font-size: .95em !important

// modal Search
.modal-content
  .hash-group
    padding-top: 1rem
    margin-left: -.5rem
    margin-right: -.5rem
    a
      &.text-link
        font-size: .9rem
        color: #558fb9
        display: inline-block
        padding: .25rem .5rem
  .form-search-group
    .form-select
      border-width: 0 0 1px 0
      border-radius: 0
      width: 100%
      height: 60px
      font-weight: 300
      font-size: 1.25rem
      border-color: $gray-600
      +sm-down
        font-size: 1.1rem
      &:focus
        outline: 0
        box-shadow: 0 0 0 rgba(0,0,0,0)
    .form-search-input
      position: relative
      .customize-input
        cursor: text
        border: none
        color: #e4e4e4
        font-weight: 300
        font-size: 1.25rem
        width: 100%
        height: 60px
        padding: 0 0 0 .8rem
        border-bottom: 1px solid $gray-600
        color: $gray-900
        border-radius: 0
        +sm-down
          font-size: 1.1rem
        &:focus
          outline: 0
      .btn
        position: absolute
        right: 0
        top: 8px
        width: 50px
        height: 50px
        border: none
        padding: 0
        margin: 0
        .icn
          display: inline-block
          width: 20px
          svg
            width: 100%
            g
              stroke: $gray-800
      ::placeholder
        color: $gray-900
      ::-webkit-input-placeholder
        color: $gray-900
      :-ms-input-placeholder
        color: $gray-900
      ::-moz-placeholder
        color: $gray-900
#goTop
  width: 42px
  height: 42px
  border-radius: 50%
  background: $primary-dark
  position: fixed
  bottom: 30px
  right: 30px
  z-index: 982
  display: none
  align-items: center
  justify-content: center
  cursor: pointer
  &.show
    display: flex
  svg
    width: 18px
    height: 18px
    stroke: #fff

.homeIntroduction
  +sm-down
    overflow: hidden
  .card
    .card-photo
      max-width: 200px
      margin: auto
      color: $gray-500
      +font-size(16px)
    h3
      +font-size(28px)
      font-weight: 600
      color: $gray-900
.homeClient
  +sm-down
    overflow: hidden
  .features-info
    height: 100%
    display: flex
    justify-content: center
    flex-direction: column
    +md-up
      padding-left: 5rem
    &.left
      padding-left: 0
      +md-up
        padding-right: 5rem
    .info-item
      &:not(:last-child)
        +md-up
          margin-bottom: 2rem
    h4
      +font-size(28px)
      font-weight: 600
      color: $gray-900
      margin-bottom: 1rem
      +md-up
        +font-size(32px)
    ul
      // list-style-type: disc
      // list-style-position: inside
      list-style-type: none
      padding: 0
      margin: 0
      +font-size(20px)
      +md-down
        +font-size(16px)
      li
        margin-bottom: .5rem
        padding-left: 25px
        position: relative
        &:before
          content: ""
          display: block
          position: absolute
          left: 0
          top: 9px
          width: 16px
          height: 16px
          background: url('../images/icon-checked.svg') 0 0 no-repeat
          background-size: 100% 100%

.homeFeatures
  +sm-down
    overflow: hidden
    position: relative
    &:after
      content: ''
      display: block
      width: 70%
      height: 1px
      position: absolute
      top: 0
      left: 16%
      background: #eee
  .card-feature
    text-align: center
    margin: 30px auto
    max-width: 240px
    +sm-down
      margin: 15px auto
    .card-photo
      margin: 0 auto 1.5rem
      svg
        width: 48px
        height: 48px
        +md-up
          width: 68px
          height: 68px
    h4
      +font-size(14px)
      font-weight: 600
      color: $gray-900
      +md-up
        +font-size(20px)
      +lg-up
        +font-size(22px)
    p
      +font-size(13px)
      color: $gray-500
      +md-up
        +font-size(14px)

.homePartner
  +sm-down
    overflow: hidden

// .all-partners
//   display: block
//   padding: 0 1rem
//   +sm-up
//     display: none
//   img
//     width: 100%
.partner-lists
  padding: 30px 0 0
  margin-left: -15px
  margin-right: -15px
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  +sm-up
    padding: 30px 0
  +md-up
    margin: 0
    padding: 50px
  .slick-arrow
    &.slick-prev
      left: -20px
    &.slick-next
      right: -20px
  .slick-current
    .item
      +sm-down
        padding: 0 45px
        transform: scale(1.2)
  .item
    padding: 12px
    display: flex
    flex: 0 0 33.3333%
    width: 33.3333%
    +sm-up
      padding: 12px 20px
    +md-up
      flex: 0 0 33.3333%
      width: 33.3333%
      padding: 12px 15px
    +lg-up
      padding: 12px 25px
      flex: 0 0 20%
      width: 20%
    a
      display: block
      margin: auto
    img
      width: 100%
      max-width: 168px
      object-fit: contain
      margin: auto
      +md-up
        height: 68px
      +lg-up
        height: 88px
      &.w-sm
        max-width: 136px
      &.h-sm
        max-height: 50px
        +sm-up
          max-height: 68px
        +lg-up
          max-height: 82px
      
.topbanner
  position: relative
  background: #000
  .text-group
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    z-index: 8
    color: #fff
    display: flex
    align-items: center
    > .container
      position: relative
      z-index: 12
    .breadcrumb
      color: #FFF
    .breadcrumb-item
      color: #FFF
      +font-size(28px)
      font-weight: 300
      +md-down
        +font-size(20px)
      +sm-down
        +font-size(16px)
      &::before
        color: #FFF
        font-weight: 300
      &.active
        a
          font-weight: 300
      a
        color: #FFF
    &:after
      content: ''
      display: block
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
      z-index: 0
      background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%)
.ratio
  &.ratio-banner
    opacity: 0.5
    &::before
      --bs-aspect-ratio: 23.6%
      +md-down
        --bs-aspect-ratio: 42.6%
      +lg-down
        --bs-aspect-ratio: 52.6%
    &.contact
      &::before
        --bs-aspect-ratio: 36%
        +md-down
          --bs-aspect-ratio: 40%
        +lg-down
          --bs-aspect-ratio: 76%
    img
      object-fit: cover
.contactForm
  position: relative
  z-index: 2
  background: #020c15
  &.sigle-page
    min-height: 100vh
    display: flex
    align-items: center
  &.light
    background: $gray-100
    h2
      color: #000
    .btn
      border-color: $warning
      color: $gray-800
      background: $warning
    .form-panel
      &.offset_top
        background: #FFF
        margin-top: -14rem
        margin-bottom: 3rem
        border-radius: 8px
        padding: 3rem 2rem
        +md-down
          margin-top: -5rem
        .form-floating
          .form-control, .form-select
            background: $gray-100
  h2
    color: #FFF
  .mt-5
    margin-top: 48px !important
  .mb-5
    margin-bottom: 48px !important
  .mb-4
    margin-botton: 36px !important
  .mb-3
    margin-botton: 24px !important
  hr
    border-color: $gray-300

  .submit_done
    border-radius: 3px
    padding: 50px 30px
    text-align: center
    background: rgba(255,255,255,.9)
    box-shadow: 0 5px 15px rgba(0,0,0,.6)
    max-width: 480px
    margin: auto
    h4
      font-weight: 600
      margin-bottom: 1.2rem
  .contact-bg
    position: absolute
    z-index: -1
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0.15
    small
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      text-align: center
      font-size: 10px
      display: inline-block
      padding: 10px 0
      opacity: 0.8
    img
      width: 100%
      height: 100%
      object-fit: cover
  .btn
    border-radius: 2px
    padding: .75rem 2rem
    min-width: 180px
    +sm-down
      width: 100%

  .form-floating
    .form-control, .form-select
      border: 0
      border-radius: 2px
      &.required ~ label
        &:before
          content: '*'
    .form-control:focus, .form-control:not(:placeholder-shown), .form-control-plaintext, .form-select
      box-shadow: none
      & ~ label
        color: $primary
        opacity: 1
.customers-wrapper
  margin: 60px auto 20px
.warehouse-customers
  padding-bottom: 60px
  +md-down
    padding-bottom: 30px
  .slick-arrow
    background: transparent
    +md-down
      margin-top: -50px
    &:hover::after
      border-color: #000000
    &.slick-next
      right: -50px
      +xl-down
        right: -30px
      +lg-down
        right: -10px
      +md-down
        right: 0px
    &.slick-prev
      left: -50px
      +xl-down
        left: -30px
      +lg-down
        left: -10px
      +md-down
        left: 0px
  .item
    text-align: center
    margin: auto
    padding: 15px 15px
    height: 80px
    transition: 0.5s transform
    transform: scale(0.8)
    +md-up
      padding: 0 25px
      height: 68px
      transform: scale(1)
      margin: 50px 0
    &.nnr
      transform: scale(1)
      +md-up
        height: 120px
        margin: 20px 0
      
    a
      display: block
      color: #000
      height: 100%
      text-align: center
    p
      padding: 20px
      font-size: 16px
      font-weight: 400
      text-align: center
      margin-top: 10px
      margin-bottom: 0
      padding: 0
      min-height: 24px
      position: relative
      +sm-down
        margin-top: 5px
        font-size: 14px
    .sr-only
      position: absolute
      width: 1px
      height: 1px
      padding: 0
      margin: -1px
      overflow: hidden
      clip: rect(0, 0, 0, 0)
      white-space: nowrap
      border-width: 0
  
    img
      height: 100%
      width: 100%
      object-fit: contain
      +md-down
        min-width: 120px
        min-height: 68px
    // img
    //   height: 80px
    //   width: auto
    //   max-width: 180px
    //   object-fit: contain
    //   margin: auto

.sectionWarehouse
  // background-color: #0F222A
  background-color: #fff
  position: relative
  min-height: 100vh
  display: flex
  align-items: center
  justify-content: center
  padding-left: 1rem
  padding-right: 1rem
  .container
    position: relative
    z-index: 10
    .row
      margin-left: -40px
      margin-right: -40px
      *[class^='col']
        padding-left: 40px
        padding-right: 40px
  .wms-flow
    +md-up
      display: grid
      grid-gap: 0px 0px
      grid-auto-columns: 1fr
    +lg-up
      grid-gap: 40px 60px
    +xl-up
      grid-gap: 100px 60px
  .process-item
    position: relative
    margin-bottom: 60px
    +md-up
      margin-bottom: 0px
    &.shop
      grid-area: shop
      +md-up
        grid-area: 1 / 1 / 2 / 12
      +lg-up
        grid-area: 1 / 1 / 2 / 4
      .card-content
        +md-up
          margin-bottom: 0
        +lg-up
          min-height: 235px
    &.wms-1
      grid-area: wms-1
      margin-top: 130px
      +md-up
        margin-top: 0px
        align-self: flex-end
        grid-area: 2 / 1 / 4 / 6
        .card-content
          height: 300px
      +lg-up
        align-self: flex-start
        grid-area: 1 / 6 / 2 / 9
        .card-content
          height: auto
    &.wms-2
      grid-area: wms-2
      +md-up
        grid-area: 4 / 1 / 5 / 6
      +lg-up
        grid-area: 2 / 1 / 3 / 3
    &.wms-3
      grid-area: wms-3
      +md-up
        grid-area: 5 / 1 / 6 / 6
      +lg-up
        grid-area: 2 / 3 / 3 / 5
    &.wms-4
      grid-area: wms-4
      +md-up
        grid-area: 5 / 7 / 6 / 12
      +lg-up
        grid-area: 2 / 5 / 3 / 7
    &.wms-5
      grid-area: wms-5
      +md-up
        grid-area: 4 / 7 / 5 / 12
      +lg-up
        grid-area: 2 / 7 / 3 / 9
    &.wms-6
      grid-area: wms-6
      display: flex
      flex-direction: column-reverse
      +md-up
        display: block
        grid-area: 2 / 7 / 3 / 12
      +lg-up
        grid-area: 1 / 9 / 2 / 11
    &.customer
      grid-area: customer
      +md-up
        grid-area: 3 / 7 / 4 / 12
      +lg-up
        grid-area: 2 / 9 / 3 / 11
    .ul
      li
        line-height: 1.25
        margin-bottom: 8px
    .card-customer
      border-radius: 12px
      background: #f2f3f7
      padding: 15px 25px
      display: flex
      justify-content: center
      align-items: center
      box-shadow: 0 2px 15px rgba(0,0,0,.5)
      position: relative
      &.return
        margin-bottom: 60px
        +md-up
          margin-bottom: 50px
        +lg-up
          margin-bottom: 0
      &.wms
        +md-up
          margin-bottom: 50px
        +lg-up
          margin-top: 90px
          margin-bottom: 0
        &:after, &:before
          border-color: $primary
      &:after, &:before
        content: ''
        display: block
        width: 20px
        height: calc(100% - 20px)
        position: absolute
        top: 10px
        border: 3px solid #b28754
        z-index: 0
      &:after
        right: 8px
        border-width: 3px 3px 3px 0
        border-radius: 0 8px 8px 0
      &:before
        left: 8px
        border-width: 3px 0 3px 3px
        border-radius: 8px 0 0 8px
      h4
        font-size: 16px
        font-weight: 600
        color: #000
      .card-avatar
        img
          width: 66px
          height: 66px
          border-radius: 50px

    .process-arr

      .text
        white-space: nowrap
      .arrow
        +lg-up
          height: 40px
          background-size: auto 100%
          flex-grow: 1
      .arrow-r
        +lg-up
          flex-grow: 1
          background-image:  url('../images/arrow-r.svg')
          background-position: center right
      .arrow-l
        +lg-up
          background-image:  url('../images/arrow-l.svg')
    .arr-1
      position: absolute
      display: flex
      bottom: -10px
      left: 50%
      transform: translate(-50%, 100%)
      +md-up
        bottom: -100px
        transform: initial
        left: unset
      +lg-up
        top: -53%
        width: 80%
        left: calc(100% + 25px)

      +xl-up
        top: -30%
      .text
        padding-right: 15px
    .arr-2
      display: flex
      position: absolute
      flex-direction: row-reverse
      top: -10px
      right: 50%
      transform: translate(50%, -100%)
      +md-up
        top: -45px
        right: 0
        transform: translateY(-100%)
      +lg-up
        top: 45%
        width: 90%
        margin-top: 80px
        flex-direction: row-reverse
        right: calc(100% + 20px)
      +xl-up
        width: 80%
        top: 55%
        right: calc(100% + 25px)
      .text
        padding-left: 15px
    .arr-3
      display: none
      +lg-up
        display: block
        position: absolute
        bottom: -11px
        left: -185%
        width: 242%
        transform: translateY(100%)
      +xl-up
        left: -172%
        width: 226%
        bottom: -40px

    .arr-4
      // +lg-up
      position: absolute
      top: 45%
      right: -5px
      width: 50px
      height: 40px
      background-size: auto 100%
      background-image:  url('../images/arrow-r.svg')
      background-position: center right
      transform: translateX(100%)
    .arr-5
      +md-up
        position: absolute
        top: 150px
        left: -5px
        width: 40px
        height: 40px
        background-size: auto 100%
        background-image:  url('../images/arrow-l.svg')
        transform: translateX(-100%)
      +lg-up
        top: 40%
        left: -5px
        width: 50px
        height: 40px
      +xl-up
        top: 45%
      &.return
        background-image:  url('../images/arrow-l2.svg')
    .arr-6
      +md-up
        position: absolute
        top: -5px
        left: calc(50% - 20px)
        width: 40px
        height: 40px
        background-size: 100%  auto
        background-image:  url('../images/arrow-t2.svg')
        transform: translateY(-100%)
      +lg-up
        top: -20px
        width: 40px
        height: 60px
    .arr-7
      width: 40px
      height: 50px
      background-size: 100%  auto
      background-position: center bottom
      background-image:  url('../images/arrow-b.svg')
      +md-up
        width: 30px
      +lg-up
        display: none
    .arr-8
      width: 40px
      height: 50px
      background-size: 100%  auto
      background-image:  url('../images/arrow-t.svg')
      +md-up
        width: 30px
      +lg-up
        display: none
    .arr-9
      position: absolute
      bottom: -50px
      left: calc(50% - 20px)
      width: 40px
      height: 40px
      background-size: 100%  auto
      background-position: center bottom
      background-image:  url('../images/arrow-b.svg')
      +md-up
        bottom: 0px
    .arr-10
      position: absolute
      top: -40px
      left: calc(50% - 20px)
      width: 40px
      height: 40px
      background-size: 100%  auto
      background-image:  url('../images/arrow-t.svg')
    img
      width: 100%
    .pcard
      padding-top: 90px
      position: relative
      margin-top: 2rem
      +lg-up
        padding-top: 56px
        height: 100%
      +xl-up
        padding-top: 90px
    .card-screen
      width: 150px
      height: 150px
      position: relative
      z-index: 10
      position: absolute
      top: 0px
      left: calc(50% - 75px)
      +lg-up
        width: 120px
        height: 120px
        left: calc(50% - 60px)
      +xl-up
        width: 150px
        height: 150px
        left: calc(50% - 75px)
    .card-icon
      width: 120px
      height: 120px
      background: #FFF
      padding: 30px
      position: relative
      z-index: 9
      border-radius: 50%
      box-shadow: 0 5px 12px rgba(#000000, .18), 0 8px 12px inset rgba(#ffffff, 1), 0 1px 18px inset rgba(#4B555C, .25)
      position: absolute
      top: 0px
      left: calc(50% - 60px)
      +md-up
        width: 100px
        height: 100px
        padding: 20px
        left: calc(50% - 50px)
        top: 20px
      +lg-up
        width: 80px
        height: 80px
        padding: 20px
        left: calc(50% - 40px)
        top: 0px
      +xl-up
        width: 120px
        height: 120px
        padding: 30px
        left: calc(50% - 60px)
    .card-content
      border-radius: 12px
      background: #FFF
      padding: 40px 25px 15px
      display: flex
      justify-content: center
      align-items: center
      box-shadow: 0 2px 10px rgba(0,0,0,.1)
      position: relative
      +md-up
        margin-bottom: 50px
        min-height: auto
      +lg-up
        margin-bottom: 0
        min-height: 250px
      +xl-up
        min-height: 200px
      &:after, &:before
        content: ''
        display: block
        width: 20px
        height: calc(100% - 20px)
        position: absolute
        top: 10px
        border: 3px solid $primary
        z-index: 0
      &.return
        &:after, &:before
          border-color: #b28754
      &:after
        right: 8px
        border-width: 3px 3px 3px 0
        border-radius: 0 8px 8px 0
      &:before
        left: 8px
        border-width: 3px 0 3px 3px
        border-radius: 8px 0 0 8px
      &.red
        &:after, &:before
          border-color: $danger
      .brand
        width: 120px
        margin: 5px auto 25px
        &.gowarehouse
          width: 140px
      h4
        font-size: 16px
        font-weight: 600
        color: #000
      ul
        padding: 0
        font-size: 14px
        li
          line-height: 1.2
          margin-bottom: 8px
.app-preview
  +md-down
    max-width: 320px
    margin: auto
  +sm-down
    max-width: 240px

.support-section
  .support-lists    
    margin-top: 5rem
    .item
      width: 100%
      display: flex
      align-items: center
      max-width: 480px
      margin: auto
      &:not(:last-child)
        margin-bottom: 20px 
        padding-bottom: 20px
        border-bottom: 1px solid #e0e0e0
      img
        width: 180px
        flex: 0 0 180px
        height: 80px
        object-fit: contain
        object-position: right
      .text
        flex-grow: 1
        padding-left: 20px
.highlight
  background: linear-gradient(to top, #fff30c 40%, #ffffff 60%)
.features-caption
  list-style: disc
  li
    line-height: 1.5
    padding: 4px 0
.switch-language
  .dropdown-item
    &:hover,&:focus,&:active,&.active
      background: $primary
      color: #fff
